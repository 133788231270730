<template>
  <b-modal
    :title="`Adicionar tarefa a ${workspace_name}`"
    centered
    v-model="openModal"
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <!-- Formulario -->
    <validation-observer ref="ModalProjectCosts">
      <hr class="invoice-spacing mb-3" />
      <b-form>
        <b-row
          class="my-1 m-2 text-left mt-4 d-flex justify-content-center flex-column"
        >
          <!-- PROJETOS -->
          <b-form-group class="mb-2">
            <label for="category" class="font-weight-bold text-primary"
              >Projetos :</label
            ><br />
            <label>
              <div
                @click="handleShowArquived()"
                :style="{ color: 'blue', padding: '5px 0', cursor: 'pointer' }"
              >
                {{
                  showArquivded ? 'Ocultar arquivados' : 'Mostrar arquivados'
                }}
              </div></label
            >
            <b-form-select
              v-if="projects.length > 0"
              id="projectSelect"
              v-model="item.project_id"
              :options="projects"
              value-field="id"
              text-field="name"
              placeholder="Selecione um projeto"
              @change="getProjectStages"
            ></b-form-select>
          </b-form-group>

          <b-form-group class="mb-2"     v-if="item.project_id">
            <label for="category" class="font-weight-bold text-primary"
              >Etapa do projeto :</label
            ><br />
            <b-form-select
          
              v-model="item.project_stage_id"
              :options="project_stages"
              value-field="id"
              text-field="stage_name"
              placeholder="Selecione uma etapa"
            ></b-form-select>
          </b-form-group>


          <!-- TITULO -->
          <b-form-group class="mb-2">
            <label class="font-weight-bold text-primary">Titulo:</label>
            <b-form-input v-model="item.title"></b-form-input>
          </b-form-group>

          <!-- DATA DE REFERÊNCIA -->
          <b-form-group class="mb-2" :disabled="item.isRepeatActive">
            <label for="referenceDate" class="font-weight-bold text-primary"
              >Data :</label
            >
            <b-form-input
              id="referenceDate"
              type="date"
              v-model="item.date"
            ></b-form-input>
          </b-form-group>

          <!-- HORA DE INÍCIO -->
          <b-form-group class="mb-2">
            <label for="startTime" class="font-weight-bold text-primary"
              >Hora de Início:</label
            >
            <b-form-input
              maxlength="5"
              id="startTime"
              type="time"
              value="09:20"
              v-model="item.start"
              @input="item.start = formatHour($event)"
            ></b-form-input>
          </b-form-group>

          <!-- HORA DE FIM -->
          <b-form-group class="mb-2">
            <label for="endTime" class="font-weight-bold text-primary"
              >Hora de Fim:</label
            >
            <b-form-input
              maxlength="5"
              id="endTime"
              type="time"
              v-model="item.end"
              @input="item.end = formatHour($event)"
            ></b-form-input>
          </b-form-group>

          <!-- Descricao -->
          <b-form-group class="mb-2">
            <label class="font-weight-bold text-primary">Descriçao:</label>
            <b-form-textarea v-model="item.notes"></b-form-textarea>
          </b-form-group>
        </b-row>

        <hr class="invoice-spacing mb-3" />
      </b-form>
    </validation-observer>

    <!-- Botoes -->
    <b-row class="justify-content-around d-flex">
      <!-- back -->
      <b-button variant="warning" class="mr-2" @click="$emit('closeModal')"
        >Voltar
      </b-button>

      <!-- back -->
      <b-button @click="saveTask" class="mr-2">Salvar </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import {
  BModal,
  BForm,
  BRow,
  BFormGroup,
  BFormSelect,
  BButton,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
//import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'new-task',
  props: {
    pre_time: { type: Object, require: false },
    open: {
      type: Boolean,
      default: false,
    },
    costs_variables: { type: Object, require: false },
  },
  components: {
    BModal,
    BForm,
    BRow,
    BFormGroup,
    BFormSelect,
    BButton,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
  },

  data() {
    return {
      showArquivded: false,
      workspace_name: '',
      openModal: this.open,
      project_stages: [],
      item: {
        project_id: null,
        title: '',
        date: null,
        start: '',
        end: '',
        notes: '',
        direct_cost: '0',
        real_direct_cost: '0',
        indirect_cost: 0,
        real_indirect_cost: '0',
      },
      member_id: '',
      projects: [],
      loading: false,
    };
  },

  created() {
    this.getMemberId();
    this.getProjects();
    console.log(this.user);
    this.workspace_name = this.$store.getters.currentWorkspace.workspace_title;
  },

  watch: {
    open(newVal) {
      this.openModal = newVal;
    },
    openModal(newVal) {
      if (!newVal) {
        this.$emit('update:open', false);
      }
    },
    pre_time: {
      handler(newPreTime) {
        this.item.start = this.transformNumberInHour(newPreTime.start);
        this.item.date = this.dateFormatter(newPreTime.date, 'yyyy-MM-dd');
        this.item.end = this.transformNumberInHour(newPreTime.end);
      },
      deep: true,
    },
  },

  methods: {
    async getProjectStages() {
      this.project_stages = [];
      this.$store
        .dispatch('getProjectStages', {
          project_id: this.item.project_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.project_stages = resp.map((item) => ({
            id: item.id,
            stage_name: item.stage_name,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getMemberId() {
      this.$store
        .dispatch('getUserMember', {
          id: this.$store.getters.user.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.member_id = resp[0].member_id;
          this.hour_per_month =
            resp[0] && resp[0].member ? resp[0].member.hour_per_month : 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async saveTask() {
      const startTime = this.item.start.split(':');
      const endTime = this.item.end.split(':');

      const startDate = new Date();
      startDate.setHours(startTime[0], startTime[1]);

      const endDate = new Date();
      endDate.setHours(endTime[0], endTime[1]);

      if (endDate <= startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hora de fim deve ser maior que a hora de início',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        });
        return;
      }

      this.item.start = this.transformInCompleteData(this.item.start);
      this.item.end = this.transformInCompleteData(this.item.end);

     // const redate = moment(this.item.date).utcOffset(-6, true);

      const duration = (endDate - startDate) / (1000 * 60 * 60);

      this.$store
        .dispatch('saveMyTask', {
          ...this.item,
          indirect_cost: Math.round(
            (this.costs_variables.average_office_cost /
              this.costs_variables.hour_per_month) *
              duration
          ),
          data: this.item.start,
          member_id: this.member_id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa cadastrada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });

            this.item = {
              project_id: null,
              title: '',
              date: null,
              start: '',
              end: '',
              notes: '',
              direct_cost: '0',
              real_direct_cost: '0',
              indirect_cost: '0',
              real_indirect_cost: '0',
            };
            this.$emit('closeModal');
          }
        })
        .catch((err) => {
          const errorMessage =
            err.response?.data?.message ||
            err.message ||
            'An unexpected error occurred';
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMessage,
              icon: 'ErrorIcon',
              variant: 'danger',
            },
          });
        });
    },
    async getProjects() {
      this.$store
        .dispatch('_getProjects', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          resp.data.map((e) => {
            if (this.showArquivded) {
              this.projects.push({
                id: e.id,
                name: `${e.project_code} - ${e.project_name} ${
                  e.is_archived ? '(Arquivado)' : ''
                }`,
              });
            } else {
              if (e.is_archived) {
                return null;
              } else {
                this.projects.push({
                  id: e.id,
                  name: `${e.project_code} - ${e.project_name} ${
                    e.is_archived ? '(Arquivado)' : ''
                  }`,
                });
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    transformNumberInHour(numero) {
      if (numero >= 0 && numero <= 23) {
        const horasFormatadas = numero < 10 ? `0${numero}` : `${numero}`;
        return `${horasFormatadas}:00`;
      } else {
        return 'Número inválido. Por favor, insira um número de 0 a 23.';
      }
    },

    transformInCompleteData(hour) {
      const dataAtual = new Date(this.item.date);
      dataAtual.setDate(dataAtual.getDate() + 1);
      dataAtual.setHours(hour.split(':')[0]);
      dataAtual.setMinutes(hour.split(':')[1]);

      return this.dateFormatter(dataAtual, 'yyyy-MM-dd HH:mm:ss');
    },

    formatHour(hour) {
      const [hours, minutes] = hour.split(':');
      if (parseInt(minutes) > 59) {
        return `${hours}:59`;
      }
      return hour;
    },
    handleShowArquived() {
      this.showArquivded = !this.showArquivded;
      this.projects = [];
      this.getProjects();
    },
  },
};
</script>

<style></style>
